import * as React from "react"
import { Link } from "gatsby"
import { FooterRecCard } from "../components/resources"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { v4 as uuidv4 } from "uuid"
import Dar from "@mui/icons-material/DoubleArrow"

const breadData = [
  {
    id: 1,
    ink: "/",
    label: "HOME",
  },
  {
    id: 3,
    ink: "/healthcare/",
    label: "HEALTHCARE",
  },
]

const resourcesList = [
  {
    title: "Penetration Testing",
    subtitle: "",
    LinkUr: "https://sp1.sdcdn.app/pdf/Pen-Test-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "Vigilant365™ ",
    subtitle: "Enhanced Microsoft 365 Detection",
    LinkUr: "https://sp1.sdcdn.app/pdf/V365-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
  {
    title: "VigilantMVDR",
    subtitle: " Managed Vulnerability Detection and Response",
    LinkUr: "https://sp1.sdcdn.app/pdf/MVDR-Solution-Brief.pdf",
    cat: "SOLUTION BRIEF",
  },
]

const EvolvingThreats = () => (
  <Layout>
    <Seo title="HEALTHCARE" />
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>

    <div className="grid--bg--for-it-ops-2">
      <section className="section-title-top--platform">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <h1>HEALTHCARE</h1>
              <h4>PROTECTING YOUR SYSTEMS AND PATIENTS</h4>
              <p className="content">
                Our Security Never Sleeps so Your Patients Are Safe
              </p>
              <Link to="/contact/" className="nav-section--cta-btn">
                <div className="d-flex align-items-center">
                  <span
                    style={{ marginLeft: "25px" }}
                    className="nav-section--cta-title pr-5"
                  >
                    get started
                  </span>
                  <Dar className="chevy" sx={{ fontSize: 15, marginLeft: 2 }} />
                </div>
              </Link>
            </div>
            <div className="section-col-2"></div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-copy-img-r about-us--hero-img-healthcare">
      <div className="container">
        <div className="row">
          <div className="section-col-1 desk-only">
            <div className="side-binder about-us--gives">
              <h3>
                DEFENDING YOUR <br /> MISSION
              </h3>
              <h6>
                REGULATORY COMPLIANCE. <br />
                PATIENT DATA PROTECTION.
              </h6>
            </div>
          </div>
          <div className="section-col-2">
            <div className="side-binder about-us--gives mob-only">
              <h1>OUR MISSION</h1>
              <h3>TO BE VIGILANT</h3>
            </div>
            <p>
              Have you ever seen that alarming moment in movies when the bad guy
              sneaks into the hospital room and changes some key information on
              the good guy’s medical chart? Today the bad guys are everywhere,
              and they enter the hospital virtually – constantly trying to steal
              private and sensitive data. In 2020, there were 616 healthcare
              data breaches of at least 500 records each, the largest being
              Trinity Health: 3,320,726 records stolen.
            </p>
            <p>
              Even worse, consider the malware on out-of-date software in heart
              monitors, delaying or degrading data. What if these or other
              critical systems are taken off-line? These are literally issues of
              life and death. This is why Vigilant provides our healthcare
              clients with 24/7/365 real-time observation and analysis of every
              login attempt, endpoint user, communication or data forward – all
              supported by our constant study of global trends, including
              healthcare-specific threats.
            </p>
            <p>
              Vigilant also assists with medical regulatory compliance,
              supplying key HIPAA and HITRUST requirements, including client
              data protection, malware defense and vulnerability monitoring.
            </p>

            <p>
              Additionally, Viglant’s CyberDNA® Command and Control Center
              provides maximum visibility far beyond compliance: a streamlined
              service system with the least distraction from your ongoing
              patient services.
            </p>
          </div>
        </div>
      </div>
    </section>

    <div className="grid--bg --for-it-ops-1--center">
      <section className="section-client-quote">
        <div className="container">
          <div className="row">
            <div className="section-col-1">
              <div className="section-col-1--content">
                <h3>
                  “I have been a Vigilant customer for [several] years. I have
                  been extremely happy with the services they provide because I
                  can sleep at night knowing I am not getting to get breached. I
                  know the Vigilant staff is truly best in the business and are
                  looking out for my [organization’s] best interests.”
                </h3>
                <hr />
                <h6>- CLIENT IN HEALTHCARE INDUSTRY</h6>
                <p className="pBlue">
                  (For security reasons, Vigilant never reveals the identity of
                  our clients)
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    <section className="section-resources">
      <div className="container">
        <div className="row">
          <div className="section-col-1">
            <h3>RESOURCES</h3>
          </div>
          <div className="section-col-2">
            <ul className="resources-list">
              {resourcesList.map(data => {
                return (
                  <FooterRecCard
                    altTxt={data.title}
                    title={data.title}
                    subTitle={data.subtitle}
                    recCat={data.cat}
                    LinkUrl={data.LinkUr}
                    key={uuidv4()}
                  />
                )
              })}
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div className="breadcrumbs">
      <nav className="container-fluid p-sides-med">
        <ul>
          {breadData.map(breadcrumb => (
            <li key={breadcrumb.id}>
              <Link to={breadcrumb.ink}>{breadcrumb.label}</Link>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  </Layout>
)

export default EvolvingThreats
